import React, { useEffect, createContext, useContext, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Divider, Layout, Menu, Spin, Typography } from 'antd';
import axios from 'axios';
import { AuthContext, NavBarContext, AuthInfoContext } from '../App';
import './FAQ.css';
const { Title, Text, Paragraph } = Typography;
function FAQ() {
  const [activeIndex, setActiveIndex] = useState(null);
  const onTitleClick = (index) => {
    setActiveIndex(index);
  };
  const navigate = useNavigate();
  const {isAuthenticated, setIsAuthenticated, authFailReason, setAuthFailReason, verifyAuth} = useContext(AuthContext);
  const {toggleNavBarVisibility, setSelectedKey, selectedKey} = useContext(NavBarContext);
  const { authInfo, setAuthInfo } = useContext(AuthInfoContext);

  useEffect(() => {
    setSelectedKey('4');
    toggleNavBarVisibility(true);
    return () => {
      toggleNavBarVisibility(true);
    };
  }, []);

  const faqData = [
    {
      title: "sectionBookings",
      section: 'Bookings',
    },
    {
      title: "How does this work?",
      content: "As tickets for high-demand timeslots are often sold out, our system continually checks for tickets that become available when existing passengers cancel. As such, we are able to secure tickets for you even when they are sold out.",
      section: '',
    },
    {
      title: "What's the pricing structure like?",
      content: <>Our current prices are as follows:<br /><ul>
        <li><b>Towards Johor</b> - $9.40</li>
        <br />
        <li><b>Towards Singapore</b> - $8.80</li>
        <br />
        <li><b>Custom Dates</b> - Additional 50¢</li>
        </ul>
        <p>You can get a better estimate for the prices by going to the 'Bookings' page, clicking on 'Add Booking', and selecting your desired travel date and direction of the train.</p>
        </>,
      section: '',
    },
    {
      title: "How do I make a booking?",
      content: <>To create a booking, you first need to add a passenger. You can do so on the 'Passengers' page of this website. Please ensure that all the details are correct when filling up the form, as they will be used to book your ticket. Once you have added a passenger, you can proceed to the 'Bookings' page to create a booking for that passenger.<br/><br/>Your booking request will first be reviewed by our team, then you will be notified to make payment after it has been approved. When payment is successful, our work begins to secure your booking.</>,
      section: '',
    },
    {
      title: "Why can I choose multiple timeslots when making a booking?",
      content: "By choosing multiple timeslots, you increase your odds of securing a ticket. This is because our system will check for tickets for all the timeslots you have chosen, and will secure the first ticket that is available in any of them.",
      section: '',
    },
    {
      title: "Why can't I book for more than one person in one booking?",
      content: "Due to the nature of our system, the odds that multiple bookings are cancelled at the same time (and hence, being available at the same time) is very low. As such, bookings are fulfilled one at a time. You are welcome to create multiple bookings, and we will try our best to fulfil them as the tickets become available.",
      section: '',
    },
    {
      title: "How would you recommend I go about booking for a group then?",
      content: "You can create multiple bookings with the same timeslot and our system will queue them up such that subsequent bookings will match the confirmed timeslot of the first booking. Do note that each booking is only mapped to one passenger, and is treated separately. This could lead to a case where some passengers in the group have their bookings confirmed, while others do not if tickets are not fulfilled.",
      section: '',
    },
    {
      title: "How do I know if my booking is successful?",
      content: "You will receive a notification (with an image of your booking confirmation) via Telegram once your booking is successful, you can also check your booking status on the 'Bookings' page of this website.",
      section: '',
    },
    {
      title: "I've made my booking far in advance, and it's been a few days but my booking is not confirmed yet.",
      content: "Typically bookings are confirmed in the 3-7 days before the travel date. The odds of having your booking confirmed increases as the travel date approaches. If you're uncomfortable with the uncertainty, you can cancel your booking at any time. Please see the cancellations section for more information.",
      section: '',
    },
    {
      title: "How do I verify my booking with the staff at the departure gate?",
      content: <><ul><li>For trains from Singapore to JB, there will be staff at the gate that will scan your passport (using mobile devices) to verify the details against their system.</li><li>For trains from JB to Singapore, there are autogates that will verify your passport.</li></ul>
      <br/><br/>
      In some cases (e.g. when there is a train delay), they might ask to see your ticket. In this case, use the booking confirmation sent to you via the Telegram bot, and the staff will manually verify your ticket against your passport.</>,
      section: '',
    },
    {
      title: "Do I have to make bookings for my kids as well?",
      content: "Based on KTMB's policy, children under 4 years old travel for free but will be required to register at the ticket counter before departure. You will need to create a booking for your child if they are 4 years old or older.",
      section: '',
    },
    {
      title: "How do I make payment for my booking?",
      content: "We use a trusted payment provider called Stripe to process your payment. Currently, we only take PayNow payments. You will receive a link to make payment via Telegram once your booking request has been reviewed. Once payment is made, you will receive another notification confirming that your payment has been received and that we have started working on your booking.",
      section: '',
    },
    {
      title: "sectionAmendments",
      section: 'Booking Amendments'
    },
    {
      title: "Can I amend my booking?",
      content: "After a booking is confirmed, we do not allow any amendments. You can, however, add additional timeslots to your booking via the dashboard.",
      section: '',
    },
    {
      title: "How do I add additional timeslots to my booking?",
      content: "You can add additional timeslots to your booking via the dashboard. Please note that once you've added additional timeslots to your booking, you will not be able to remove them.",
      section: '',
    },
    {
      title: "sectionBookingRejected",
      section: 'Booking Rejections',
    },
    {
      title: "Why was my booking rejected?",
      content: "There are a few reasons why your booking may have been rejected. The most common reason is that there is too much demand for the date and timeslot you have chosen, and we are unable to fulfil your request. We recommend reaching out to our support team on Telegram at @SteadyShuttleSupport to find out more. Our team can assist in finding alternative timeslots that can be fulfiled for you.",
      section: '',
    },
    {
      title: "sectionCustomerSupport",
      section: 'Customer Support'
    },
    {
      title: "What are your customer support hours?",
      content: "We usually respond during office hours on weekdays. For new bookings & cancellation, please create the booking/self cancel from the bookings page, as we will not manually process cancellations through our support channel.",
      section: ''
    },
    {
      title: "sectionDates",
      section: 'Booking Dates',
    },
    {
      title: "Why am I only allowed to select limited dates when making a booking?",
      content: "We currently have limited computing capacity to fulfil bookings for different dates. Therefore, we are limiting automated bookings to mainly over the coming weekends.",
      section: '',
    },
    {
      title: "Can I book dates that further into the future (more than a month ahead)?",
      content: "The reason why we only allow bookings for dates in the coming month is because cancellations are less likely the further into the future it is. If you'd still like to go ahead and book for a date that is further into the future, please make a 'Custom Date' booking. Please note that the 'No Cancellation Period' for such bookings will be in effect until 3 days before the departure date.",
      section: '',
    },
    {
      title: "What are custom date bookings?",
      content: "Custom Date bookings are bookings for dates that are not listed in the dropdown menu when creating a booking. You can select 'Other Dates' in the dropdown menu when making the booking request - you can find it on the bottom of the dropdown selections. After making the request, please contact our support team at @SteadyShuttleSupport to arrange your desired booking date. Please note that custom date bookings are subject to a longer 'No Cancellation Period', and have an additional fee of $0.50 per booking.",
      section: '',
    },
    {
      title: "Why is there an additional fee for custom date bookings?",
      content: "Custom date bookings require additional administrative work to arrange, as well as additional provisioning of computing resources specifically for your booking. As such, we charge an additional fee of $0.50 per booking.",
      section: '',
    },
    {
      title: "sectionCancellationNew",
      section: 'Cancellations & Refunds',
    },
    {
      title: "How can I cancel my booking?",
      content: "You can cancel your booking through the SteadyShuttle web app. Please note that the refund amount will vary based on the time remaining till the first departure you have selected.",
      section: "",
    },
    {
      title: "Cancellation Charges",
      content: <><p>Cancellation charges will apply to the refund amount to your payment method, based on when you cancel. These are applicable for each individual booking. The cancellation charges are as follows:</p> <ul> <li>If you cancel within the last 3 hours prior to <b>last selected departure</b>, there will be a charge of 20 cents.</li> <li>If you cancel within the last 48 hours prior to <b>last selected departure</b>, there will be a charge of $1.</li> <li>If you cancel more than 48 hours prior to <b>last selected departure</b>, there will be a charge of 50% of the booking amount.</li> </ul></>,
      section: "",
    },
    {
      title: "Automatic Refunds",
      content: <>If the booking was not fulfilled by the time of <b>last selected departure</b> selected, the system will automatically issue a full refund within 1 hour after the <b>last selected departure</b> time. You will receive a notification via Telegram once the refund has been issued.</>,
      section: "",
    },
    {
      title: "I selected multiple departure timeslots, how do cancellations work in general?",
      content: <>If you selected multiple timeslots, such as train 72, 74, 76, you may manually cancel at no charge at the time of departure for the <b>last selected train</b> (i.e. train 76). However, our system will automatically process a refund if it has not been booked within an hour of the <b>last selected train's departure</b> (i.e. train 76).</>,
      section: "",
    },
    {
      title: "Cancellation Timeframe",
      content: <><p>Please be aware that cancellations can be made at any time prior to departure, but the applicable charges will be based on the time of cancellation.</p> <p>If you have any questions or need assistance, feel free to contact our support team through the SteadyShuttle web app.</p></>,
      section: "",
    },
    {
      title: "Cancelling a Confirmed Booking",
      content: <><p>If you have already received the PDF confirmation of your booking, unfortunately we will not be able to cancel it. If you'd like, we may cancel it on the train booking system, but we cannot offer a refund for such cases. This is because the payment for our service is not for the ticket, but the service of booking the sold out ticket. Once it has been booked, the service is rendered fulfilled. Hence there are no refunds for confirmed bookings.</p></>,
      section: "",
    },
  ];

  const renderedItems = faqData.map((item, index) => {
    const active = index === activeIndex ? 'active' : '';

    return (
      <React.Fragment key={item.title}>
        {
          item.section == '' ?
        <>
          <div
            className={`title ${active}`}
            onClick={() => onTitleClick(index)}
          >
            <i className="dropdown icon"></i>
            {item.title}
          </div>
          <div className={`content ${active}`}>
            <p>{item.content}</p>
          </div>
          </>
          :
          <Title style={{ fontSize: "1.4em"}}>{item.section}</Title>
        }
      </React.Fragment>
    );
  });

  return (
    <div style={{  width: '90%' }}>
      <div className="ui styled accordion" style={{ textAlign: 'left' }}>
        <Title level={1} style={{ marginTop: '0em' }}>FAQ</Title>
        {renderedItems}
        <Divider/>
        <Text>Have more queries? Reach out to our support team on Telegram at @SteadyShuttleSupport and we'll be happy to help.</Text>
      </div>
    </div>
  )
}

export default FAQ