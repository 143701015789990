import React, { useEffect, createContext, useContext, useState, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams, Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Layout, Menu, Spin, Typography, List, Modal, Form, Input, Button, DatePicker, Radio, Divider, Table, Checkbox, Select, Space, Popconfirm, Tag, Tooltip, Popover, message } from 'antd';
import axios from 'axios';
import moment from 'moment';
import { AuthContext, NavBarContext, AuthInfoContext } from '../App';
import { UserOutlined, UserAddOutlined, UserDeleteOutlined, PauseCircleOutlined, LoadingOutlined, UnorderedListOutlined, CheckCircleOutlined, ExclamationCircleOutlined, FileAddOutlined, QuestionCircleOutlined, SyncOutlined, ClockCircleOutlined, CloseCircleOutlined, EyeOutlined, FileTextOutlined, ArrowLeftOutlined, ArrowRightOutlined, DollarCircleOutlined, FileExcelOutlined } from '@ant-design/icons';
const DEV_ENDPOINT = 'http://localhost:5051';
const PROD_ENDPOINT = 'https://api.steadyshuttle.com';
const BACKEND_ENDPOINT = process.env.NODE_ENV === 'development' ? DEV_ENDPOINT : PROD_ENDPOINT;
const { Title, Text, Paragraph } = Typography;
const { Option } = Select;

function Bookings() {
  const navigate = useNavigate();
  const { isAuthenticated, setIsAuthenticated, authFailReason, setAuthFailReason, verifyAuth } = useContext(AuthContext);
  const { toggleNavBarVisibility, setSelectedKey, selectedKey } = useContext(NavBarContext);
  const { authInfo, setAuthInfo } = useContext(AuthInfoContext);
  const [isLastMinuteBooking, setIsLastMinuteBooking] = useState(false);
  const [isWeekendBooking, setIsWeekendBooking] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isSelfCancelModalVisible, setIsSelfCancelModalVisible] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [selectedDirection, setSelectedDirection] = useState(0);
  const [selectedTrains, setSelectedTrains] = useState([]);
  const [passengers, setPassengers] = useState([]);
  const [passengerLoaded, setPassengerLoaded] = useState(false);
  const [formSubmissionLoading, setFormSubmissionLoading] = useState(false);
  const [lastMinuteDates, setLastMinuteDates] = useState([]);
  const [hoursUntilDeparture, setHoursUntilDeparture] = useState(0);
  const [refundType, setRefundType] = useState(0);
  const [refundValue, setRefundValue] = useState(0);
  const [otherSelected, setOtherSelected] = useState(false);
  const [fetchingRefundValue, setFetchingRefundValue] = useState(false);
  const [showAddTimeslots, setShowAddTimeslots] = useState(false);
  const [newTimeslotValues, setNewTimeslotValues] = useState([]);

  const [cost, setCost] = useState(0);

  const [addTimeslotsForm] = Form.useForm();

  const [isLoadingTimeslots, setIsLoadingTimeslots] = useState(false);
  const [timeslotAvailability, setTimeslotAvailability] = useState({});

  const formRef = useRef(null);

  const handleAddTimeslotValues = (value) => {
    setNewTimeslotValues(value)
  }

  const handleTrainSelect = async (selectedTrains) => {
    // Update form values
    formRef.current?.setFieldsValue({
      train: selectedTrains
    });
    
    // Get current date and direction from form
    const currentValues = formRef.current?.getFieldsValue();
    
    // Refresh availability after selection changes
    if (currentValues?.date && currentValues?.direction !== undefined) {
      await fetchTimeslotAvailability(currentValues.date, currentValues.direction);
    }
  };

  const fetchPassengers = () => {
    axios.get(BACKEND_ENDPOINT + '/api/passengers', {
      headers: {
        cid: authInfo.cid,
        sess: authInfo.session_key,
      },
    })
      .then((response) => {
        // console.log(response)
        const data = response.data.passengers.map((passenger) => ({
          ...passenger,
          key: passenger._id,
        }));
        // console.log(data)
        setPassengers(response.data.passengers);
        setPassengerLoaded(true);
      })
      .catch((error) => {
        console.error('Error fetching passengers:', error);
      });
  };

  const fetchBookings = () => {
    axios.get(BACKEND_ENDPOINT + '/api/bookings', {
      headers: {
        cid: authInfo.cid,
        sess: authInfo.session_key,
      },
    })
      .then((response) => {
        const data = response.data.bookings.map((booking) => ({
          ...booking,
          key: booking._id,
        }));
        // console.log(response.data.bookings)
        setBookings(data);
        setTableData(data);
      })
      .catch((error) => {
        console.error('Error fetching bookings:', error);
      });
  };

  const handlePatchTimeslots = async () => {
    // get selected booking id and new timeslot values
    const id = selectedBooking._id
    const newTimeslots = newTimeslotValues

    const formattedValues = {
      train: newTimeslots,
    };

    try {
      const response = await axios.patch(BACKEND_ENDPOINT + '/api/bookings/add-timeslots/' + id, formattedValues, {
        headers: {
          cid: authInfo.cid,
          sess: authInfo.session_key,
        },
      });
      fetchBookings();
      setShowAddTimeslots(false)
      setSelectedBooking(null)
      message.success('Timeslots added successfully!');
    } catch (error) {
      console.error('Error adding timeslots:', error.response.data.error);
      message.error('Error: ' + error.response.data.error);
    }
  }

  const handleAddBooking = async (bookingDetails) => {
    setFormSubmissionLoading(true);
    try {
      const formattedValues = {
        date: bookingDetails.date,
        direction: bookingDetails.direction,
        passenger: bookingDetails.passenger,
        train: bookingDetails.train,
      };
      const response = await axios.post(BACKEND_ENDPOINT + '/api/bookings', formattedValues, {
        headers: {
          cid: authInfo.cid,
          sess: authInfo.session_key,
        },
      });
      formRef.current.resetFields();
      fetchBookings();
      setIsModalVisible(false);
      setFormSubmissionLoading(false);
    } catch (error) {
      console.error('Error adding booking:', error);
      console.log(error.response.data.error);
      message.error(error.response.data.error)
      setFormSubmissionLoading(false);
    }
  };

  const fetchTimeslotAvailability = async (selectedDate, selectedDirection) => {
    if (!selectedDate || selectedDirection === undefined) return;
    
    setIsLoadingTimeslots(true);
    try {
      const response = await axios.get(BACKEND_ENDPOINT + '/api/timeslot-availability', {
        headers: {
          cid: authInfo.cid,
          sess: authInfo.session_key
        },
        params: {
          date: selectedDate,
          direction: selectedDirection
        }
      });

      if (response.data.success) {
        setTimeslotAvailability(response.data.availability);
      } else {
        console.error('Failed to fetch timeslot availability:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching timeslot availability:', error);
    } finally {
      setIsLoadingTimeslots(false);
    }
  };

  // Effect to fetch availability when date or direction changes
  useEffect(() => {
    if (formRef.current?.getFieldValue('date') && formRef.current?.getFieldValue('direction') !== undefined) {
      fetchTimeslotAvailability(
        formRef.current.getFieldValue('date'),
        formRef.current.getFieldValue('direction')
      );
    }
  }, [formRef.current?.getFieldValue('date'), formRef.current?.getFieldValue('direction')]);

  // Update the train options rendering to include availability tags
  const renderTrainOptions = (trains) => {
    return trains.map(train => {
      const availability = timeslotAvailability[train.value] || { status: 'Unknown', color: 'default' };
      return (
        <Select.Option key={train.value} value={train.value}>
          <Space>
            {train.label}
            {isLoadingTimeslots ? (
              <Spin size="small" />
            ) : (
              <Tag color={availability.color}>{availability.status}</Tag>
            )}
          </Space>
        </Select.Option>
      );
    });
  };

  useEffect(() => {
    verifyAuth();
    setSelectedKey('3');
    toggleNavBarVisibility(true);
    fetchBookings();
    return () => {
      toggleNavBarVisibility(true);
    };
  }, []);



  const columns = [
    {
      title: 'Date',
      dataIndex: 'targetDate',
      key: 'targetDate',
      defaultSortOrder: 'descend',
      sorter: (a, b) => moment(a.targetDateObj).unix() - moment(b.targetDateObj).unix(),
      render: (text, record) => (
        <Space>
          <Text>{text}{record.targetDayOfWeek && ", " + record.targetDayOfWeek.substring(0, 3)}</Text>
        </Space>
      )
    },
    {
      title: (<>
        <Tooltip title="Hover over the train number tags to view the actual timing.">
          Train <ExclamationCircleOutlined style={{
            marginLeft: '0.3em'
          }} />
        </Tooltip>
      </>),
      dataIndex: 'targetTrain',
      key: 'targetTrain',
      render: (text, record) => (

        <Space style={{ display: 'flex', flexWrap: 'wrap' }}>
          {text.map((train) => (
            <Tooltip key={record._id + train} title={(
              <span>{[
                ...trainsTowardsJohor,
                ...trainsTowardsSingapore,
              ].find(t => t.value === train).label}</span>
            )}>
              <Tag>{train}</Tag>
            </Tooltip>
          ))}
        </Space>
      )
    },
    {
      title: 'Direction',
      dataIndex: 'targetDirection',
      key: 'targetDirection',
      // defaultSortOrder: 'ascend',
      sorter: (a, b) => a.targetDirection - b.targetDirection,
      render: (text) => (text === 1 ? (<Tag color="orange">SG <ArrowLeftOutlined /> JB</Tag>) : (<Tag color="green">SG <ArrowRightOutlined /> JB</Tag>)), // assuming 0 for inbound and 1 for outbound
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      // defaultSortOrder: 'ascend',
      sorter: (a, b) => {
        switch (a.status) {
          case 'working':
            return -1;
          case 'reviewing':
            return b.status === 'working' ? 1 : -1;
          case 'payment_pending':
            return b.status === 'working' || b.status === 'reviewing' ? 1 : -1;
          case 'booked':
            return b.status === 'user_cancelled' || b.status === 'admin_cancelled' || b.status === 'refunded' || b.status === 'cancelled' || b.status === 'rejected' ? -1 : 1;
          case 'user_cancelled':
            return b.status === 'admin_cancelled' || b.status === 'refunded' || b.status === 'cancelled' || b.status === 'rejected' ? -1 : 1;
          case 'admin_cancelled':
            return b.status === 'refunded' || b.status === 'cancelled' || b.status === 'rejected' ? -1 : 1;
          case 'refunded':
            return b.status === 'cancelled' || b.status === 'rejected' ? -1 : 1;
          case 'cancelled':
            return b.status === 'rejected' ? -1 : 1;
          case 'rejected':
            return 1;
          default:
            return 0;
        }
      },
      filters: [
        {
          text: 'Working',
          value: 'working',
        },
        {
          text: 'Booked',
          value: 'booked',
        },
        {
          text: 'Cancelled',
          // all cancelled statuses
          value: ['user_cancelled', 'admin_cancelled', 'refunded', 'cancelled', 'rejected'],
        },
      ],
      render: (text, record) => {
        if (text === "working") {
          return <Tooltip title={("Booking ID: " + record._id)}>
            <Tag icon={<SyncOutlined spin />} color="blue">Worker Running</Tag>
          </Tooltip>;
        } else if (text === "reviewing") {
          return <Tooltip title={("Booking ID: " + record._id)}>
            <Tag icon={<ClockCircleOutlined />} color="default">Pending Review</Tag>
          </Tooltip>;
        } else if (text === "paused") {
          return <Tag icon={<PauseCircleOutlined />} color="warning">Paused</Tag>;
        } else if (text === "payment_pending") {
          return <Tag icon={<ClockCircleOutlined />} color="gold">Pending Payment</Tag>;
        } else if (text === "queued") {
          return <Tooltip title={"We've put this booking into the queue. Queueing bookings links them together and helps prevent your passengers from being booked onto different train timeslots."}><Tag icon={<SyncOutlined />} color="gold">In Queue <QuestionCircleOutlined/></Tag></Tooltip>;
        } else if (text === "booked") {
          return <Tag icon={<CheckCircleOutlined />} color="success">Booked</Tag>;
        } else if (text === "user_cancelled") {
          return <Tag icon={<CloseCircleOutlined />} color="error">Cancelled By You</Tag>;
        } else if (text === "admin_cancelled") {
          return <Tag icon={<CloseCircleOutlined />} color="error">Cancelled By Admin</Tag>;
        } else if (text === "refunded") {
          return <Tag icon={<CloseCircleOutlined />} color="error">Refunded</Tag>;
        } else if (text === "cancelled") {
          return <Tag icon={<CloseCircleOutlined />} color="error">Cancelled</Tag>;
        } else if (text === "rejected") {
          return (<Link to="/support"><Tag icon={<ExclamationCircleOutlined />} color="error">Rejected - Contact Support</Tag>
          </Link>);
        }
      }
    },
    {
      title: 'Passenger',
      dataIndex: ['passenger', 'name'],
      key: ['passenger', 'name'],
      sorter: (a, b) => a.passenger.name.localeCompare(b.passenger.name),
      render: (text, record) => (
        <Space>
          <Tooltip title={(
            <div>
              <Text style={{ color: "#fff" }}>Name: {record.passenger.name}</Text>
              <br />
              <Text style={{ color: "#fff" }}>Passport: {record.passenger.passport}</Text>
              <br />
              <Text style={{ color: "#fff" }}>Contact: {record.passenger.contact}</Text>
            </div>
          )}>

            <Text>{record.passenger.name.split(' ')[0] + '...'}</Text> <EyeOutlined />
          </Tooltip>
        </Space>
      )
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <>
          {/* <Button onClick={() => handleDelete(record._id)}>Delete</Button> */}
          {
            record.status == "payment_pending" &&
            (<Button onClick={() => handlePayment(record._id)} style={{ marginRight: '1em', marginBottom: '1em'}}>Make Payment</Button>)
          }
          {
            ((record.status == "reviewing") || (record.status == "payment_pending")) &&
            (<Button onClick={() => handleDelete(record._id)}>Cancel</Button>)
          }
          {
            (record.status == "working" || record.status == "queued") &&
            ((record.self_cancellable == false) ?
            (
              <Button onClick={() => alert("Self cancelling is only available for bookings created after 31 July 2023 under the new policy changes. Please contact support.")}>Cancel</Button>
            ) :
            (
              <div>
              {record.status == "working" && <Button onClick={() => handleAddTimeslots(record._id)} disabled={false} style={{ marginRight: '1em', marginBottom: '1em'}}>Add Timeslots</Button>}
              <Button onClick={() => handleSelfCancel(record._id)} disabled={fetchingRefundValue}>Cancel & Refund</Button>
              </div>
            ) )
          }
        </>
      ),
    },
  ];

  const handleAddTimeslots = (id) => {
    const booking = bookings.find((booking) => booking._id === id);
    setSelectedBooking(booking);
    setNewTimeslotValues(booking.targetTrain);
    addTimeslotsForm.setFieldsValue({
      train: booking.targetTrain
    });
    setShowAddTimeslots(true);
  };

  const handleSelfCancel = (id) => {
    // filter passenger from passengers based on matching id
    setFetchingRefundValue(true)
    setSelectedBooking(bookings.filter((booking) => booking._id === id)[0])
    // load data from endpoint
    axios.get(BACKEND_ENDPOINT + '/api/cancel-booking/' + id, {
      headers: {
        cid: authInfo.cid,
        sess: authInfo.session_key,
      },
    })
      .then((response) => {
        const { hours_until_departure, refund_type, refund_value } = response.data;
        console.log(`Hours until departure: ${hours_until_departure}`);
        console.log(`Refund type: ${refund_type}`);
        console.log(`Refund value: ${refund_value}`);

        setHoursUntilDeparture(hours_until_departure)
        setRefundType(refund_type)
        setRefundValue(refund_value)
        setFetchingRefundValue(false)
        setIsSelfCancelModalVisible(true)
      })
      .catch((error) => {
        console.error('Error getting refund information:', error);
      });
  };


  const handleDelete = (id) => {
    // filter passenger from passengers based on matching id
    setSelectedBooking(bookings.filter((booking) => booking._id === id)[0])
    setIsDeleteModalVisible(true)
  };

  const handleDeleteConfirm = () => {
    console.log(`Deleting passenger ${selectedBooking.name} (${selectedBooking.passport})`);

    message.info('Cancelling booking..');
    axios.delete(BACKEND_ENDPOINT + '/api/bookings/' + selectedBooking._id, {
      headers: {
        cid: authInfo.cid,
        sess: authInfo.session_key,
      },
    })
      .then((response) => {
        // console.log(response)
        fetchBookings();
        message.success('Booking cancelled successfully!');
      })
      .catch((error) => {
        console.error('Error deleting booking:', error);
      });

    setIsDeleteModalVisible(false);
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalVisible(false);
  };

  const handleSelfCancelCancel = () => {
    setIsSelfCancelModalVisible(false);
    setRefundValue(-1)
  }

  const handleSelfCancelConfirm = () => {
    // setRefundValue(-1)
    setFetchingRefundValue(true)

    axios.post((BACKEND_ENDPOINT + '/api/cancel-booking/' + selectedBooking._id), {}, {
      headers: {
        cid: authInfo.cid,
        sess: authInfo.session_key,
      },
    })
      .then((response) => {
        const { status } = response.data;
        if (status == 'ok') {
          message.success("Successfully cancelled & refunded")
        } else {
          message.info(status)
        }
        fetchBookings()
        setFetchingRefundValue(false)
      })
      .catch((error) => {
        console.error('Error getting refund information:', error);
      });
    setIsSelfCancelModalVisible(false);
  }

  const handlePayment = (id) => {
    // get booking object from bookings based on matching id
    let payment_booking = bookings.filter((booking) => booking._id === id)[0]
    // open payment_booking.payment_url in new tab
    window.open(payment_booking.payment_url, "_blank")
  };



  // const getWeekendDates = () => {
  //   const today = moment();
  //   const nextFriday = moment().day(5 + (today.day() > 5 ? 7 : 0));
  //   const nextSaturday = nextFriday.clone().add(1, 'day');
  //   const nextSunday = nextFriday.clone().add(2, 'day');
  //   const nextNextFriday = nextFriday.clone().add(7, 'day');
  //   const nextNextSaturday = nextSaturday.clone().add(7, 'day');
  //   const nextNextSunday = nextSunday.clone().add(7, 'day');
  //   return [nextFriday, nextSaturday, nextSunday, nextNextFriday, nextNextSaturday, nextNextSunday];
  // };

  const getWeekendDates = () => {
    const today = moment();
    const weekends = [];
    const num_weekends = 24
    for (let i = 0; i < (num_weekends + 1); i++) { // 
      const nextFriday = moment().day(5).add(7 * i, 'day');
      // const nextFriday = moment().day(5 + (today.day() > 5 ? 7 : 0)).add(7 * i, 'day'); // disable after 5th day
      const nextSaturday = nextFriday.clone().add(1, 'day');
      const nextSunday = nextFriday.clone().add(2, 'day');
      weekends.push(nextFriday, nextSaturday, nextSunday); 
    }
    
    return weekends;
  };
  

  const trainsTowardsJohor = [
    { label: "8.30AM (Train #72)", value: 72 },
    { label: "9.45AM (Train #74)", value: 74 },
    { label: "11.00AM (Train #76)", value: 76 },
    { label: "12.30PM (Train #78)", value: 78 },
    { label: "1.45PM (Train #80)", value: 80 },
    { label: "3.00PM (Train #82)", value: 82 },
    { label: "4.15PM (Train #84)", value: 84 },
    { label: "5.30PM (Train #86)", value: 86 },
    { label: "6.45PM (Train #88)", value: 88 },
    { label: "8.00PM (Train #90)", value: 90 },
    { label: "9.15PM (Train #92)", value: 92 },
    { label: "10.30PM (Train #94)", value: 94 },
    { label: "11.45PM (Train #96)", value: 96 }
  ];

  const trainsTowardsSingapore = [
    { label: "5.00AM (Train #61)", value: 61 },
    { label: "5.30AM (Train #63)", value: 63 },
    { label: "6.00AM (Train #65)", value: 65 },
    { label: "6.30AM (Train #67)", value: 67 },
    { label: "7.00AM (Train #69)", value: 69 },
    { label: "7.30AM (Train #71)", value: 71 },
    { label: "8.45AM (Train #73)", value: 73 },
    { label: "10.00AM (Train #75)", value: 75 },
    { label: "11.30AM (Train #77)", value: 77 },
    { label: "12.45PM (Train #79)", value: 79 },
    { label: "2.00PM (Train #81)", value: 81 },
    { label: "3.15PM (Train #83)", value: 83 },
    { label: "4.30PM (Train #85)", value: 85 },
    { label: "5.45PM (Train #87)", value: 87 },
    { label: "7.00PM (Train #89)", value: 89 },
    { label: "8.15PM (Train #91)", value: 91 },
    { label: "9.30PM (Train #93)", value: 93 },
    { label: "10.45PM (Train #95)", value: 95 }
  ];



  return (
    <div style={{ display: 'flex', alignItems: 'left', justifyContent: 'left', height: '100%', }}>
      <div style={{ textAlign: 'left', width: '100%' }}>
        <Title level={1} style={{ marginTop: '0em' }}><FileTextOutlined /> Bookings</Title>
        <Space style={{ marginBottom: 16 }}>
          <Button type="primary" onClick={() => setIsModalVisible(true)}>
            <FileAddOutlined /> Add Booking
          </Button>
        </Space>
        <Table columns={columns} responsive={"scroll"} dataSource={tableData} rowKey="_id" />
        <Modal
          title={<><FileAddOutlined /> Add Booking</>}
          // visible={isModalVisible}
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
        >
          <Form
            layout="vertical"
            ref={formRef}
            onFinish={handleAddBooking}
          >

            <Form.Item
              name="direction"
              label="Direction"
              rules={[
                {
                  required: true,
                  message: 'Please choose a direction',
                },
              ]}
            >
              <Radio.Group
                onChange={(e) => {
                  formRef.current.setFieldsValue({ train: [] });
                  setSelectedDirection(e.target.value);
                  setCost(e.target.value === 0 ? 9.4 : 8.8);
                  const date = formRef.current.getFieldValue('date');
                  if (date && date !== '0000-00-00') {
                    fetchTimeslotAvailability(date, e.target.value);
                  }
                }}
              >
                <Radio value={0}>Towards Johor ($9.40)</Radio>
                <Radio value={1}>Towards Singapore ($8.80)</Radio>
              </Radio.Group>
            </Form.Item>


            {
              passengers.length == 0  && passengerLoaded &&
              <a href="/passengers" target="_blank">
              <p style={{ color: 'red', marginBottom: "1em"}}>It seems you have not added any passengers to your account yet. You can do so by clicking here.</p>
              </a>
            }
            <Form.Item
              name="passenger"
              label="Passenger"
              rules={[
                {
                  required: true,
                  message: 'Please choose a passenger',
                },
              ]}
            >
              <Select
                // showSearch
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                optionFilterProp="label"
                loading={!authInfo}
                onChange={(value) => {
                  formRef.current.setFieldsValue({ passenger: value });
                }}
                onFocus={fetchPassengers}
              >
                {passengers.map((passenger) => (
                  <Option key={passenger._id} value={passenger._id} label={passenger.name}>
                    {passenger.name} ({passenger.passport})
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="date"
              label="Departure Date"
              rules={[
                {
                  required: true,
                  message: 'Please choose a date',
                },
              ]}
            >
              <Select
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                  </div>
                )}
                onChange={(value) => {
                  if (value == "0000-00-00") {
                    setOtherSelected(true)
                  } else {
                    setOtherSelected(false)
                    const direction = formRef.current.getFieldValue('direction');
                    if (direction !== undefined) {
                      fetchTimeslotAvailability(value, direction);
                    }
                  }
                  
                  // check if date is in the next 2 days
                  if (moment(value).isBefore(moment().add(2, 'days'))) {
                    setIsLastMinuteBooking(true);
                  } else {
                    setIsLastMinuteBooking(false);
                  }

                  // if date is a saturday or sunday
                  if (moment(value).day() == 0 || moment(value).day() == 6) {
                    setIsWeekendBooking(true);
                  } else {
                    setIsWeekendBooking(false);
                  }
                }}
              >

                <Option key="other" value="0000-00-00">
                  All Other Dates (Mon-Thu) -{" "}
                  <Tooltip title="This option incurs an additional cost because it requires manual processing.">
                    +50¢ <QuestionCircleOutlined />
                  </Tooltip>
                </Option>
                {getWeekendDates().map((date) => (
                  <Option key={date.format('YYYY-MM-DD')} value={date.format('YYYY-MM-DD')}>
                    {date.format('ddd, DD MMM YYYY')}
                  </Option>
                ))}
              </Select>
            </Form.Item>


            <Form.Item
              name="train"
              label="Train"
              rules={[{ required: true, message: 'Please choose a train' }]}
              style={{
                marginBottom: "0.5em"
              }}
            >
              <Select
                mode="multiple"
                placeholder="Select preferred train(s)"
                loading={isLoadingTimeslots}
                disabled={isLoadingTimeslots}
              >
                {selectedDirection === 0
                  ? renderTrainOptions(trainsTowardsJohor)
                  : renderTrainOptions(trainsTowardsSingapore)}
              </Select>
            </Form.Item>
                <Text style={{
                  fontSize: "0.8em",
                  color: "#969696",
                  marginTop: "-1em",
                }}>Choose several train slots to increase the likelihood of getting a ticket quickly. We'll book the first available train among those selected.</Text>
                

            {
              selectedTrains.length > 1 &&
                <div style={{
                  marginTop: "1em",
                }}>
                {/* <Popover placement="top" title={"Group bookings will be queued."} content={"As bookings are processed one at a time, the booking status of group bookings will show 'In Queue' indicating that they are waiting for the first passengers' timeslot to be confirmed. After it has been confirmed, subsequent bookings will be updated to match the same timeslot."} trigger="click">
                  <Button danger type="dashed">Booking for multiple people? Click here.</Button>
                </Popover> */}

                <Text strong style={{
                  display: 'block'
                }}>Making a group booking with multiple timeslots?</Text>
                <Text style={{
                  fontSize: "0.8em",
                }}>
                  Select exactly the same timeslots for all passengers to ensure that they are booked on the same train. The booking status will show 'In Queue' indicating that they are waiting for the first passengers' timeslot to be confirmed. After it has been confirmed, subsequent bookings will be updated to match the same timeslot.
                </Text>
                </div>
            }
            <Divider />

            <Form.Item style={{ marginBottom: "0" }}>
              <Text strong>Total Cost: S${(cost + (otherSelected ? 0.5 : 0)).toFixed(2)}</Text>
              {
                otherSelected &&
                <Paragraph>
                  <Text style={{
                    color: 'red',
                  }}>Please contact support after your booking request is made to let us know what date you'd like to book for. </Text>
                </Paragraph>
              }
              <Paragraph>
                <Text style={{
                  fontSize: "0.8em",
                  color: "#969696"
                }}>Next Steps: Your booking request will be reviewed by our team, and you'll be notified to make payment after it has been approved.</Text>
              </Paragraph>
            </Form.Item>
            <Form.Item
            >
              <Checkbox required>
               I have read and agreed to the terms of creating a booking request outlined in the <a href="/faq" target='_blank'>FAQs</a>, including the cancellation policy.
              </Checkbox>
            </Form.Item>

            <Form.Item
            >
              <Checkbox required>
                I understand that a full refund is only possible if my booking request is not fulfilled at departure time. Early cancellations will incur <a href="/faq" target='_blank'>cancellation charges</a>, which will be deducted from the refund amount.
              </Checkbox>
            </Form.Item>
            {/* {
              isLastMinuteBooking && 
              <Form.Item
              >
                <Checkbox required>
                I understand that for last-minute bookings (made less than 48 hours from departure) that end up cancelled, whether by me or due to unfulfillment, there will be a small deduction of 20¢ from the refund amount.
                <Tooltip title="This deduction is intended to cover administrative costs associated with cancellations. It is also imposed to ensure fair use of our service and to prevent abuse."><Tag color='blue' style={{ marginLeft: '0.5em'}}>Why?</Tag></Tooltip>
                </Checkbox>
              </Form.Item>
            } */}
            {/* {
              isWeekendBooking &&
              <Form.Item
              >
              <Checkbox required>
                I understand that the SteadyShuttle support team is only available on weekdays. Therefore, any cancellation or refund requests made on weekends will be processed on the following Monday.
              </Checkbox>
              </Form.Item>
            } */}
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={formSubmissionLoading} style={{ marginRight: 8 }}>
                {
                  formSubmissionLoading ?
                    <>
                      <SyncOutlined spin /> Loading
                    </>
                    :
                    <>
                      <CheckCircleOutlined /> Confirm Booking Details
                    </>
                }
              </Button>
              <Button onClick={() => setIsModalVisible(false)} style={{ marginRight: 8 }}>
                Cancel
              </Button>
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          title={<><FileExcelOutlined /> Confirm Cancellation & Refund?</>}
          // visible={isDeleteModalVisible}
          open={isSelfCancelModalVisible}
          onCancel={handleSelfCancelCancel}
          footer={[
            <Button key="delete" type="primary" danger onClick={handleSelfCancelConfirm} disabled={fetchingRefundValue}>
              Confirm Cancel & Refund
            </Button>,
            <Button key="cancel" onClick={handleSelfCancelCancel} disabled={fetchingRefundValue}>
              Go Back
            </Button>,
          ]}
        >
          <Paragraph>
            <b><ExclamationCircleOutlined /> Please read up on our cancellation policy and its relevant charges before proceeding, this action is NOT reversible.</b>
          </Paragraph>


          {/* <Paragraph><b>{hoursUntilDeparture}</b> hours left until first selected departure for booking.</Paragraph> */}
          <Paragraph><b>{hoursUntilDeparture}</b> hours left until last selected departure for booking.</Paragraph>

          <div style={{
            fontWeight: 'bold',
            color: 'red',
          }}>
          {
            refundType == 0 ? 
            (
              <Paragraph style={{ 
                color: 'red',
              }}>Cancellation Charge: ${refundValue/100}</Paragraph>
            ) :
            (
              <Paragraph style={{ 
                color: 'red',
              }}>Cancellation Charge: {refundValue}% of original booking amount</Paragraph>
            )
          }
          </div>


          <Paragraph>
            Are you sure you want to cancel booking for {selectedBooking?.passenger.name} ({selectedBooking?.passenger.passport}) on {selectedBooking?.targetDate} (Train {selectedBooking?.targetTrain})?
          </Paragraph>
        </Modal>

        <Modal
          title={<><FileExcelOutlined /> Confirm Cancellation</>}
          // visible={isDeleteModalVisible}
          open={isDeleteModalVisible}
          onCancel={handleDeleteCancel}
          footer={[
            <Button key="delete" type="primary" danger onClick={handleDeleteConfirm}>
              Confirm Cancellation
            </Button>,
            <Button key="cancel" onClick={handleDeleteCancel}>
              Go Back
            </Button>,
          ]}
        >
          <Paragraph>
            Are you sure you want to cancel booking for {selectedBooking?.passenger.name} ({selectedBooking?.passenger.passport}) on {selectedBooking?.targetDate} (Train {selectedBooking?.targetTrain})?
          </Paragraph>

          <Paragraph>
            <ExclamationCircleOutlined /> This action cannot be reversed.
          </Paragraph>
        </Modal>


        <Modal
          title={<>Add Timeslots<br/>{selectedBooking?.passenger.name} ({selectedBooking?.passenger.passport}) on {selectedBooking?.targetDate} ({selectedBooking?.targetDayOfWeek?.substring(0,3)})</>}
          open={showAddTimeslots}
          onCancel={() => {
            setShowAddTimeslots(false);
            setNewTimeslotValues([]);
            setSelectedBooking(null);
            addTimeslotsForm.resetFields();
          }}
          onOk={() => {
            addTimeslotsForm.validateFields()
              .then(values => {
                if (values.train.toString() === selectedBooking?.targetTrain.toString()) {
                  alert("Please select additional timeslots to continue.");
                } else {
                  setNewTimeslotValues(values.train);
                  setShowAddTimeslots(false);
                  handlePatchTimeslots();
                }
              })
              .catch(info => {
                console.log('Validate Failed:', info);
              });
          }}
        >
          <Form
            form={addTimeslotsForm}
            layout="vertical"
            initialValues={{
              train: selectedBooking?.targetTrain || []
            }}
          >
            <Paragraph>
              Select the timeslots you'd like to add to your booking. Changes will affect bookings that are in queue as well.
            </Paragraph>

            <Paragraph style={{
              color: 'red'
            }}>
              Newly added timeslots cannot be removed after they have been confirmed.
            </Paragraph>
            
            <Form.Item
              name="train"
              label="Train"
              rules={[{ required: true, message: 'Please choose a train' }]}
              style={{ marginBottom: "0.5em" }}
            >
              <Select
                mode="multiple"
                placeholder="Please select timeslots"
                onChange={handleAddTimeslotValues}
              >
                {selectedBooking?.targetDirection === 0
                  ? trainsTowardsJohor.map((train) => (
                    <Select.Option
                      key={train.value}
                      value={train.value}
                      disabled={selectedBooking?.targetTrain.includes(train.value)}
                    >
                      {train.label}
                    </Select.Option>
                  ))
                  : trainsTowardsSingapore.map((train) => (
                    <Select.Option
                      key={train.value}
                      value={train.value}
                      disabled={selectedBooking?.targetTrain.includes(train.value)}
                    >
                      {train.label}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </div>
  );
};

export default Bookings;